import { useIsLoggedIn } from "@/utils/auth";

export const LOGGED_IN_APP_BASE_URL = process.env.NEXT_PUBLIC_VERCEL_URL
  ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
  : 'localhost:7777';

const API_LOCAL_BASE_URL = 'http://localhost:2020';
const API_PRODUCTION_BASE_URL = 'https://api.wolfia.com';

export const getApiBaseUrl = (): string => {
  if (process.env.NODE_ENV === 'development') {
    return API_LOCAL_BASE_URL;
  } else {
    return API_PRODUCTION_BASE_URL;
  }
};

const COOKIE_SUBDOMAINS = '.wolfia.com';

export const getCookieDomain = (): string | undefined => {
  if (process.env.NODE_ENV === 'development') {
    return undefined;
  } else {
    return COOKIE_SUBDOMAINS;
  }
};

export const getLoginUrl = (): string => {
  if (process.env.NODE_ENV === 'development') {
    return `https://inventive-nation-61-staging.authkit.app`;
  } else {
    return `https://login.wolfia.com`;
  }
}

export const redirectToLoginOrDashboard = (): string => {
  const isLoggedIn = useIsLoggedIn();
  return isLoggedIn ? '/dashboard' : getLoginUrl();
}
