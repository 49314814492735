import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { SWRConfig } from 'swr';
import { api } from '@/api';
import { ToastVariant, useToasts } from '../feedback/Toast';
import { useIsLoggedIn } from "@/utils/auth";

interface APIProviderProps {
  children: ReactNode;
}

export const APIProvider = ({ children }: APIProviderProps) => {
  const router = useRouter();
  const { showToast } = useToasts();
  const isLoggedIn = useIsLoggedIn();

  const isExcludedPath = (path: string) => {
    const excludedPaths = new Set([
      '/',
      '/404',
      '/about',
      '/evals',
      '/security',
      '/terms',
      '/privacy',
      '/pricing',
      '/contact',
      '/create-organization',
      '/login',
      '/signup',
      '/caiq-lite',
      '/signup-info',
      '/auth',
      '/forgot-password',
      '/reset-password',
    ]);

    return excludedPaths.has(path) ||
      path.includes('blog') ||
      path.includes('case-studies');
  };

  return (
    <SWRConfig
      value={{
        fetcher: async (url) => {
          try {
            const response = await api.get(url);
            return response.data;
          } catch (error) {
            if (error instanceof AxiosError && error.response?.status) {
              const { status } = error.response;
              switch (status) {
                case 401:
                  if (!isExcludedPath(router.pathname)) {
                    showToast({
                      title: 'Your session has expired. Please log in to continue.',
                      variant: ToastVariant.ERROR,
                    });
                    const redirectPath = '/';
                    if (router.pathname !== redirectPath) {
                      router.push(redirectPath);
                    }
                  }
                  break;
                case 404: {
                  const errorMessage = error.response.data?.errorMessage;
                  showToast({
                    title: errorMessage ?? 'Page not found',
                    variant: ToastVariant.ERROR,
                  });
                  const dashboardPath = '/dashboard';
                  if (router.pathname !== dashboardPath && isLoggedIn) {
                    router.push(dashboardPath);
                  }
                  break;
                }
              }
            }

            throw error;
          }
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};
