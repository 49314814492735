import { AppProps } from 'next/app';
import React, { ReactElement } from 'react';
import { GlobalProviders } from '@/components/providers/GlobalProviders';
import { useGlobalStyles } from '@/stitches.config';
import '../styles/global.css';
import '../styles/tailwind.css';
import '@radix-ui/themes/styles.css';
import '../styles/radix-ui-theme-config.css';
import { dayjsInitialization } from '@/utils/dates';
import { useMonitoringInitialization } from '@/utils/monitoring';
import { Theme } from '@radix-ui/themes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

dayjsInitialization();

const queryClient = new QueryClient();

const GlobalInitialization = ({ children }: { children: ReactElement }) => {
  useMonitoringInitialization();
  return children;
};

function App({ Component, pageProps }: AppProps) {
  useGlobalStyles();
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalProviders>
        <GlobalInitialization>
          <Theme>
            <div className="flex flex-col min-h-screen">
              <div className="flex-1">
                <Component {...pageProps} />
              </div>
            </div>
          </Theme>
        </GlobalInitialization>
      </GlobalProviders>
    </QueryClientProvider>
  );
}

export default App;
